import React, { useEffect } from "react";
import moment from 'moment';
import { withContext } from "@context";
import API from "@api";
import { useSetState, useInput } from "@utils/hooks";
import { getPathName, toLocalTime } from '@utils/helpers';
import { Modal } from "@components/Modal";
import Button from "@components/Button";
import Calendar from './calendar';

const Component = ({ closeFn, currentCategory, dispatch }) => {
  const [state, setState] = useSetState({
    data: {
      type: "folder",
      name: toLocalTime(new Date(), "MM.DD.YYYY"),
      desc: undefined,
      homeWorkDate: new Date(),
    },
    requiredData: ["type", "homeWorkDate"],
    autoFocus: true,
    btnIsDisabled: true,
    isLoading: false,
    validationErrorMessage: "",
    currentCategory,
  });

  useEffect(() => {
    setState(state => {
      state.currentCategory = currentCategory;
    });
  }, [currentCategory]);

  const renderInput = (isRequired, type, name, label, rows) => {
    const [field, fieldInput] = useInput({
      label,
      type,
      rows,
      value: state.data[name] || "",
      isRequired,
      autoFocus: true,
      onChange: (value) => onDataChange(name, value.trim()),
    });

    return fieldInput;
  };

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });

    try {
      const payload = await API.entries.create({
        companyId: state.currentCategory.objectId,
        data: {
          ...state.data,
          homeWorkDate: moment(state.data.homeWorkDate).utc().startOf('day'),
          path: getPathName(),
        }
      });

      await dispatch({
        type: "ENTRIES_CREATE",
        payload,
      });

      closeFn();
    } catch (e) {
      // console.log('@@@@', e)
      if (e.response.status === 409) {
        setState(state => {
          state.isLoading = false;
          state.btnIsDisabled = true;
        });

        dispatch({
          type: "SNACKBAR_ADD",
          payload: `Съществува домашна работа за ${state.data.name}`,
        });
      } else {
        dispatch({
          type: "SNACKBAR_ADD",
          payload: "Възникна грешка!",
        });
      }

    }
  }

  const onDataChange = (key, value) => {
    const { data, requiredData } = state;

    data[key] = value;

    let btnIsDisabled = false;
    for (let k in requiredData) {
      if (!btnIsDisabled) {
        if (!data[requiredData[k]] || data[requiredData[k]] === "") {
          btnIsDisabled = true;
        }
      }
    }

    setState(state => {
      if (key === "homeWorkDate") {
        state.data.name = toLocalTime(value, "MM.DD.YYYY");
      }

      state.data[key] = value;
      state.btnIsDisabled = btnIsDisabled;
    });


  };

  return (
    <Modal.Container size="xs">
      <Modal.Header title={`Домашна работа за <strong>${state.data.name}</strong>`} />
      <Modal.Content>
        <Calendar data={state.data.homeWorkDate} dataKey="homeWorkDate" onDataChange={onDataChange} />
        <br />
        {renderInput(false, "text", "desc", "кратко описане", 4)}
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: "grey",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
          }}
        >Откажи</Button>
        <Button
          onClick={onSave}
          theme={{
            color: "blue",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
            disabled: state.btnIsDisabled,
            isLoading: state.isLoading,
          }}
        >Създай</Button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default withContext(["currentCategory"])(Component);
